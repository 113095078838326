.scrollBtn {
  border: 0;
  outline: 0;
  position: fixed;
  bottom: 20px;
  right: 35px;
  cursor: pointer;
  background-color: $primary;
  padding: 10px;
  border-radius: 5px;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  transform: translateY(700px);
  transition: transform 0.5s ease-in-out;

  &.show {
    transform: translateY(0);
  }
  i {
    font-size: 1.5rem;
    color: $white;
  }
  @media screen and (max-width: 320px) {
    .scrollBtn {
      width: 35px;
      height: 35px;
      bottom: 10px;
    }
  }
}
