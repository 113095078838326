//
// _nav.scss
//

.nav {
  --bs-nav-link-padding-x: 0.5rem;
}
.nav-pills {
  --bs-nav-pills-border-radius: 15px;
}

.nav-pills .nav-link {
  border: 1px solid rgb(206, 212, 218);
  color: rgb(13, 47, 98);
  margin-right: 8px;
  text-transform: capitalize;
}
.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}gray-700);
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
    color: var(--#{$prefix}gray-700);
    font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}gray-300);

  .nav-item {
    position: relative;
    color: var(--#{$prefix}dark);
    .nav-link {
      border: none;
      &::after {
        content: "";
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
