.preview_card {
  color: white;
  width: 324px;
  height: 205px;
  background-image: url("../../../images/bg-ico-hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}
