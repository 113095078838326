.form-control .ant-select-selector {
  .ant-select-selection-placeholder {
    color: $gray-600;
  }
  color: $gray-700;
}

// flat select
.ColumnFilter {
  .ant-select-selector {
    // height: 36.7px !important;
    height: 25px !important;
    font-size: 0.7125rem;
  }
}
// flat select
.ant-select-item.ant-select-item-option {
  .ant-select-item-option-content {
    font-size: 0.7125rem;
  }
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 100%;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single
  .ant-select-selector {
  align-items: center;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}
:where(.css-sk7ap8)[class*="ant-tree-select"] {
  font-size: 0.7125rem;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-select-multiple
  .ant-select-selection-item {
  height: 18px;
  margin-top: 2px;
  margin-bottom: 4px;
  line-height: 16px;
  font-size: 0.7125rem;
  align-items: center;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single.ant-select-open
  .ant-select-selection-item {
  line-height: 20px;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-tree-select-dropdown
  .ant-select-tree {
  font-size: 0.7125rem;
}
:where(.css-dev-only-do-not-override-sk7ap8.decreaseSwitcherWidth).ant-tree-select-dropdown
  .ant-select-tree
  .ant-select-tree-switcher {
  width: 10px;
}
