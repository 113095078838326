//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
    font-weight: 700;
    // text-align: center;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}
// customzied by Hassan
.table-light {
  // --bs-table-color: #0052cc;
  // --bs-table-color: #74788d;
  // --bs-table-color: $primary;
  // --bs-table-bg: #ceebe9;
}
.table {
  --bs-table-hover-bg: #eff2f7;
  // --bs-table-hover-color: #f46a6a;
  --bs-table-hover-color: $primary;
  // --bs-table-striped-color: #0052cc;
  // --bs-table-border-color: #ceebe9;
}
.table th {
  font-weight: 700;
  color: $gray-700;
}
.table td {
  color: $gray-700;
  font-weight: 500;
}

.table > :not(caption) > * > * {
  padding: 0.2rem;
  font-size: 0.65rem;
  // text-align: center;
}
.editable-table-input {
  width: 100%;
  // height: 100%;
  // border: 1px solid $blue-100;
  border: 1px solid $success;
  border-radius: 5px;
  // padding: 1rem 0.5rem;
  padding: 0rem 0.5rem 1rem;
  transition: all 0.75s ease-in-out;
}
.editable-table-input:focus,
.editable-table-textarea:focus {
  border-color: $blue-400;
}
.editable-table-input:read-only,
.editable-table-textarea:read-only {
  background-color: $gray-200;
}

.editable-table-textarea {
  // width: 100%;
  // height: 100%;
  // border: 1px solid $blue-100;
  border: 1px solid transparent;
  border-radius: 5px;
  // padding: 1rem 0.5rem;
  padding: 0rem 0.5rem 1rem;
  transition: all 0.75s ease-in-out;
}

.sticky-header th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
}

/* Chrome, Safari, Edge, Opera */
.editable-table-input::-webkit-outer-spin-button,
.editable-table-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.editable-table-input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
