#sticky-top-boxes {
  position: sticky;
  top: $header-height;
  z-index: 4;
  background-color: $body-bg;
  padding-inline: 10px;
  padding-block: 10px;

  // height: 250px;
  // height: 200px;
  // height: 280px;
  // height: 220px;
  height: 100px;
  .neon-box {
    min-height: 115px;
  }
  // the heading
  .sticky-box-heading {
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  // neon text
  .neon-text {
    font-weight: 500;
    font-size: 10px;
    // truncate the text after 2 lines of content
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    -webkit-animation: glow 0.5s ease-in-out infinite alternate;
    -moz-animation: glow 0.5s ease-in-out infinite alternate;
    animation: glow 0.5s ease-in-out infinite alternate;
  }
}

#sticky-sidebox {
  position: sticky;
  //   top: 310px;
  // top: ($header-height + 250px + 5px);
  // top: ($header-height + 200px + 5px);
  // top: ($header-height + 220px + 5px);
  top: ($header-height + 100px);
  // height: 450px;
  height: 455px;
  // height: 470px;
  border-radius: 5px;
  // border: 2px solid $success;
}

// @keyframes glow {
//   from {
//     text-shadow: 0 0 5px #bd7777, 0 0 8px #e6dada;
//   }
//   to {
//     text-shadow: 0 0 8px #fcdada, 0 0 11px #e6dada;
//   }
// }

@keyframes glow {
  0% {
    text-shadow: 0 0 15px #ffffff, 0 0 15px #ffffff;
  }
  100% {
    text-shadow: 0 0 15px #d02323, 0 0 15px #ffffff;
  }
}

// @keyframes glow {
//   from {
//     text-shadow: 0 0 5px #b86c6c, 0 0 8px #984d4d, 0 0 11px #f46a6a;
//   }
//   to {
//     text-shadow: 0 0 8px #fcdada, 0 0 11px #fcdada, 0 0 14px #f46a6a;
//     // 0 0 30px #fcdada;
//   }
// }
// @keyframes glow {
//   from {
//     text-shadow: 0 0 5px #b86c6c, 0 0 8px #984d4d;
//   }
//   to {
//     // text-shadow: 0 0 8px #fcdada, 0 0 11px #fcdada;
//     text-shadow: 0 0 8px #fcdada, 0 0 11px #984d4d;
//   }
// }

// the latest old
// @keyframes glow {
//   from {
//     text-shadow: 0 0 5px #bd7777, 0 0 8px #9b5757;
//   }
//   to {
//     text-shadow: 0 0 8px #fcdada, 0 0 11px #9b5757;
//   }
// }

// @keyframes glow {
//   form {
//     text-shadow: 0px 0px 10px #fff, 0px 0px 20px #fff,
//       0px 0px 30px lighten($red, 15%), 0px 0px 40px lighten($red, 20%),
//       0px 0px 50px lighten($red, 25%), 0px 0px 60px lighten($red, 30%);
//   }
//   to {
//     text-shadow: 0px 0px 20px #fff, 0px 0px 30px #fff,
//       0px 0px 40px lighten($red, 15%), 0px 0px 50px lighten($red, 20%),
//       0px 0px 60px lighten($red, 25%), 0px 0px 70px lighten($red, 30%);
//   }
//   // form {
//   //   text-shadow: 0px 0px 10px #fff, 0px 0px 20px #fff, 0px 0px 30px $red,
//   //     0px 0px 40px $red, 0px 0px 50px $red, 0px 0px 60px $red;
//   // }
//   // to {
//   //   text-shadow: 0px 0px 20px #fff, 0px 0px 30px #fff, 0px 0px 40px $pink,
//   //     0px 0px 50px $pink, 0px 0px 60px $pink, 0px 0px 70px $pink;
//   // }
//   // from {
//   //   text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
//   //     0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
//   // }
//   // to {
//   //   text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
//   //     0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
//   // }
// }
